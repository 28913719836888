@use "@angular/material" as mat;

html {
  color-scheme: light dark; // Automatically switches between light and dark mode based on user preferences
  @include mat.theme(
    (
      color: mat.$violet-palette,
      // Use violet color palette
      typography: Roboto,
      // Set typography to Roboto font
      density: 0 // Default density setting,,
    )
  );
}
@import "./themes/lite-purple.scss";
@import "https://cdn.jsdelivr.net/npm/instantsearch.css@8.5.1/themes/satellite-min.css";

[tabindex="-1"]:focus {
  outline: none;
}

hr {
  margin-top: 2rem;

  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  height: 0;
}

select,
button,
textarea,
input {
  vertical-align: baseline;
}

div {
  box-sizing: border-box;
}

html,
body {
  &[dir="rtl"],
  &[dir="ltr"] {
    unicode-bidi: embed;
  }
}

bdo[dir="rtl"] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

bdo[dir="ltr"] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

img {
  max-width: 100%;
}

a,
a:focus,
a:hover {
  text-decoration: none;
}
